.Request {
  flex: 4 !important;
  padding: 1em;
  position: relative;
}
.Request h2,
.Request h2 > pre {
  margin: 0;
}
.Request .headers,
.Request .payload,
.Request pre {
  white-space: normal;
}
.Request .react-json-view {
  background: white !important;
  font-family: Inconsolata, monospace !important;
  padding: 1em;
  word-break: break-all;
}
.Request .response {
  border: 1px solid #ebebeb;
  display: inline-block;
  margin-left: 1rem;
  padding: 0.25rem;
}
.Request .controls {
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-top: 2em;
  flex-wrap: wrap;
}
.Request .controls button,
.Request .controls input {
  padding: 0.25em 0.5em;
  font: inherit;
  border-radius: 3px;
}
.Request .controls button {
  cursor: pointer;
}
.Request .controls input {
  margin-top: 5px;
  display: block;
  border: 1px solid #bbb;
  width: 100%;
}
.Request .controls .delete {
  color: #db2828;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #db2828;
}
.Request .controls .delete:hover,
.Request .controls .delete:active {
  background: #db2828;
  color: #fff;
}
.Request .controls .delete:focus {
  outline: none;
  box-shadow: 0 0 3px #db2828;
}
