@import url("https://fonts.googleapis.com/css?family=Inconsolata:400,700");
@import url("https://emoji-css.afeld.me/emoji.css");

* {
  box-sizing: border-box;
}

html,
body,
#root,
.App,
.Requests,
.Request {
  height: 100%;
  overflow-y: scroll;
  color: #222;
}

body {
  -ms-overflow-style: none;
  /** For IE */
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
}

hr {
  border: none;
  height: 1px;
  background: #ebebeb;
  margin: 2rem 0;
}

button {
  border: 1px solid #222;
  border-radius: 3px;
  font: inherit;
  cursor: pointer;
  padding: 0.5em 1rem;
}

button:hover {
  background: #222;
  color: #fff;
}

.App {
  padding: 1em;
  padding-top: 3.5em;
}

h1 {
  position: absolute;
  background: #fff;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1rem 2rem;
  margin: 0;
  font-size: 1rem;
  line-height: 1rem;
  z-index: 2;
  border-bottom: 1px solid #ebebeb;
}

h1 a {
  text-decoration: none;
}

pre.bash {
  position: relative;
}

pre.bash:before {
  content: "$ ";
}

.flex {
  height: 100%;
}

body {
  font-family: "Inconsolata", monospace;
  margin: auto;
  height: 100%;
}

.Request,
.Requests {
  overflow-y: scroll;
}

.flex {
  display: flex;
}

h1,
h2,
h3 {
  font-family: "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
}

h1 a {
  color: inherit;
}

header a {
  font-weight: bold;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 2em;
  text-decoration: none;
  color: inherit;
  letter-spacing: -2px;
}

.recent {
  display: none;
}

.recent-link {
  display: block;
  padding: 1em 2em;
  margin-bottom: 1em;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eee;
}

header {
  padding: 2em 0;
}

@media all and (max-width: 30em) {
  .headers,
  .payload {
    float: none;
    width: 100%;
    padding: 0;
  }

  .meta abbr {
    margin-top: 0.5em;
    display: block;
  }
}

.request {
  border: 1px solid #eee;
  margin-top: 2em;
  border-radius: 3px;
  padding: 1em;
}

.body {
  font-size: 1.5em;
  line-height: 2;
  margin-top: 0;
}

.copy {
  padding: 0.5em 1em;
  font: inherit;
  font-size: 1.5em;
  display: block;
  width: 100%;
  margin-bottom: 1.5em;
}

.meta {
  background: #eee;
  margin-left: -1em;
  margin-right: -1em;
  margin-top: -1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
}

.meta strong {
  text-transform: uppercase;
}

.meta .show-if-proxy {
  float: right;
  text-decoration: none;
}

.request:after {
  content: " ";
  display: table;
  clear: both;
}

[data-status="error"] {
  background: #b83635;
  margin: 1em 0;
  padding: 1em;
}

[data-status="success"] {
  background: #2c962c;
  margin: 1em 0;
  padding: 1em;
}

.connection-result {
  position: relative;
  color: #fbf8f8;
}

.remove {
  float: right;
  position: absolute;
  right: 0.5em;
  top: 50%;
  color: #fbf8f8;
  font-size: 2em;
  transform: translateY(-50%);
  text-decoration: none;
}

#proxy ~ .show-if-proxy,
#proxy ~ .requests .show-if-proxy {
  display: none;
}

#proxy:checked ~ .show-if-proxy,
#proxy:checked ~ .requests .show-if-proxy {
  display: block;
}

.collapse {
  background: white;
  display: inline-block;
  height: 1em;
  width: 1em;
  text-align: center;
  font-size: 2rem;
  cursor: pointer;
}
