@import url(https://fonts.googleapis.com/css?family=Inconsolata:400,700);
@import url(https://emoji-css.afeld.me/emoji.css);
* {
  box-sizing: border-box;
}

html,
body,
#root,
.App,
.Requests,
.Request {
  height: 100%;
  overflow-y: scroll;
  color: #222;
}

body {
  -ms-overflow-style: none;
  /** For IE */
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
}

hr {
  border: none;
  height: 1px;
  background: #ebebeb;
  margin: 2rem 0;
}

button {
  border: 1px solid #222;
  border-radius: 3px;
  font: inherit;
  cursor: pointer;
  padding: 0.5em 1rem;
}

button:hover {
  background: #222;
  color: #fff;
}

.App {
  padding: 1em;
  padding-top: 3.5em;
}

h1 {
  position: absolute;
  background: #fff;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1rem 2rem;
  margin: 0;
  font-size: 1rem;
  line-height: 1rem;
  z-index: 2;
  border-bottom: 1px solid #ebebeb;
}

h1 a {
  text-decoration: none;
}

pre.bash {
  position: relative;
}

pre.bash:before {
  content: "$ ";
}

.flex {
  height: 100%;
}

body {
  font-family: "Inconsolata", monospace;
  margin: auto;
  height: 100%;
}

.Request,
.Requests {
  overflow-y: scroll;
}

.flex {
  display: flex;
}

h1,
h2,
h3 {
  font-family: "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
}

h1 a {
  color: inherit;
}

header a {
  font-weight: bold;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 2em;
  text-decoration: none;
  color: inherit;
  letter-spacing: -2px;
}

.recent {
  display: none;
}

.recent-link {
  display: block;
  padding: 1em 2em;
  margin-bottom: 1em;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eee;
}

header {
  padding: 2em 0;
}

@media all and (max-width: 30em) {
  .headers,
  .payload {
    float: none;
    width: 100%;
    padding: 0;
  }

  .meta abbr {
    margin-top: 0.5em;
    display: block;
  }
}

.request {
  border: 1px solid #eee;
  margin-top: 2em;
  border-radius: 3px;
  padding: 1em;
}

.body {
  font-size: 1.5em;
  line-height: 2;
  margin-top: 0;
}

.copy {
  padding: 0.5em 1em;
  font: inherit;
  font-size: 1.5em;
  display: block;
  width: 100%;
  margin-bottom: 1.5em;
}

.meta {
  background: #eee;
  margin-left: -1em;
  margin-right: -1em;
  margin-top: -1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
}

.meta strong {
  text-transform: uppercase;
}

.meta .show-if-proxy {
  float: right;
  text-decoration: none;
}

.request:after {
  content: " ";
  display: table;
  clear: both;
}

[data-status="error"] {
  background: #b83635;
  margin: 1em 0;
  padding: 1em;
}

[data-status="success"] {
  background: #2c962c;
  margin: 1em 0;
  padding: 1em;
}

.connection-result {
  position: relative;
  color: #fbf8f8;
}

.remove {
  float: right;
  position: absolute;
  right: 0.5em;
  top: 50%;
  color: #fbf8f8;
  font-size: 2em;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-decoration: none;
}

#proxy ~ .show-if-proxy,
#proxy ~ .requests .show-if-proxy {
  display: none;
}

#proxy:checked ~ .show-if-proxy,
#proxy:checked ~ .requests .show-if-proxy {
  display: block;
}

.collapse {
  background: white;
  display: inline-block;
  height: 1em;
  width: 1em;
  text-align: center;
  font-size: 2rem;
  cursor: pointer;
}

.Requests {
  padding: 1em;
  padding-left: 0;
  flex: 1 1;
  width: 300px;
  height: 100%;
}
.Requests h2 {
  padding-left: 1rem;
}
.Requests .enter,
.Requests .enter-done {
  transition: all 1s ease;
}
.Requests .soft {
  opacity: 0.5;
}
.Requests .enter-active {
  background: #ffcd42;
  transition: all 0.2s ease;
}
.Requests ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}
.Requests ul li {
  border-bottom: 1px solid white;
  cursor: pointer;
}
.Requests ul li:first-child time {
  border: 1px solid #ffd000;
  padding: 0.25em 0.5em;
  border-radius: 3px;
  display: inline-block;
}
.Requests ul li a {
  padding: 0.5em 1em;
  display: block;
}
.Requests ul li pre {
  text-overflow: ellipsis;
  overflow: hidden;
}
.Requests ul li:hover {
  background: #f6f6f6;
}
.Requests ul li a:not(.try-it) {
  color: inherit;
}
.Requests ul li.active {
  background: #fff;
  border: 1px solid #222;
  position: relative;
}
.Requests ul li.active:before {
  content: "";
  border: 1px solid #222;
  border-color: #222 #222 transparent transparent;
  display: block;
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translate(50%, -50%) rotate(45deg);
          transform: translate(50%, -50%) rotate(45deg);
  background: #fff;
  height: 10px;
  width: 10px;
  border-style: solid;
}
.Requests ul li a {
  text-decoration: none;
}
.Requests ul li pre {
  margin: 0;
}
.Requests ul li time {
  font-size: 0.7em;
}

.Request {
  flex: 4 1 !important;
  padding: 1em;
  position: relative;
}
.Request h2,
.Request h2 > pre {
  margin: 0;
}
.Request .headers,
.Request .payload,
.Request pre {
  white-space: normal;
}
.Request .react-json-view {
  background: white !important;
  font-family: Inconsolata, monospace !important;
  padding: 1em;
  word-break: break-all;
}
.Request .response {
  border: 1px solid #ebebeb;
  display: inline-block;
  margin-left: 1rem;
  padding: 0.25rem;
}
.Request .controls {
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-top: 2em;
  flex-wrap: wrap;
}
.Request .controls button,
.Request .controls input {
  padding: 0.25em 0.5em;
  font: inherit;
  border-radius: 3px;
}
.Request .controls button {
  cursor: pointer;
}
.Request .controls input {
  margin-top: 5px;
  display: block;
  border: 1px solid #bbb;
  width: 100%;
}
.Request .controls .delete {
  color: #db2828;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #db2828;
}
.Request .controls .delete:hover,
.Request .controls .delete:active {
  background: #db2828;
  color: #fff;
}
.Request .controls .delete:focus {
  outline: none;
  box-shadow: 0 0 3px #db2828;
}

.NewRequest {
  flex: 1 1;
}

.NewRequest script {
  color: black;
  padding: 1em;
  margin: 0;
  white-space: pre;
  margin-bottom: 1em;
}
.NewRequest .react-tabs__tab-list,
.NewRequest .react-tabs__tab--selected {
  border-color: #222;
}
.NewRequest .react-tabs__tab-list {
  margin-bottom: 0;
}
.NewRequest .react-tabs__tab-panel--selected {
  border: 1px solid #222;
  padding: 1em;
  border-top: none;
}
.NewRequest script,
.NewRequest pre {
  padding: 0.25em;
  border: 1px solid #222;
  border-radius: 3px;
  font-size: 1.2rem;
  background: #222;
  color: #fff;
}

.NewRequest h3 {
  margin-top: 0;
}

.NewRequest pre.wrap {
  white-space: pre-wrap;
  word-break: break-all;
}

